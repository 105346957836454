// src/mixin/websocket.js
export default {
  data() {
    return {
      websocket: null, // WebSocket 实例
      receivedMessage: {}, // 接收到的消息
      heartbeatTimer: null, // 心跳定时器
      reconnectionTimer: null, // 重连定时器
    };
  },
  mounted() {
    this.initWebSocket(); // 组件挂载时初始化 WebSocket 连接
  },
  methods: {
    // 初始化 WebSocket 连接
    initWebSocket() {
      if ("WebSocket" in window) {
        let user = JSON.parse(localStorage.getItem("user"));
        // this.websocket = new WebSocket(`wss://api.sd.wangxinban.cn/screenWebsocket/7865/SCqlgcsAp01m4JpSx1XmLPeIaoQhl4fs1`);
        this.websocket = new WebSocket(
          `wss://api.sd.wangxinban.cn/screenWebsocket/${user.orgId}/${user.token}`
        );

        this.websocket.onopen = () => {
          console.log("WebSocket 连接已打开");
          this.startHeartbeat(); // 启动心跳
        };

        this.websocket.onmessage = (event) => {
          console.log("收到服务器消息:", event);
          if (event.data !== "连接成功！")
            this.receivedMessage = JSON.parse(event.data);
        };

        this.websocket.onclose = () => {
          console.log("WebSocket 连接已关闭");
          this.stopHeartbeat(); // 停止心跳
          this.reconnect(); // 尝试重连
        };

        this.websocket.onerror = (error) => {
          console.error("WebSocket 连接发生错误:", error);
        };
      } else {
        alert("您的浏览器不支持 WebSocket!");
      }
    },

    // 发送消息到服务器
    sendMessage(message) {
      if (this.websocket && this.websocket.readyState === WebSocket.OPEN) {
        this.websocket.send(message);
      } else {
        console.error("WebSocket 连接未打开，无法发送消息");
      }
    },

    // 启动心跳检测
    startHeartbeat() {
      this.heartbeatTimer = setInterval(() => {
        if (this.websocket && this.websocket.readyState === WebSocket.OPEN) {
          this.websocket.send("ping"); // 发送心跳消息
        }
      }, 30000); // 每30秒发送一次心跳
    },

    // 停止心跳检测
    stopHeartbeat() {
      if (this.heartbeatTimer) {
        clearInterval(this.heartbeatTimer);
        this.heartbeatTimer = null;
      }
    },

    // 重连逻辑
    reconnect() {
      this.stopHeartbeat(); // 重连前停止心跳
      this.reconnectionTimer = setTimeout(() => {
        if (this.websocket && this.websocket.readyState === WebSocket.CLOSED) {
          this.initWebSocket(); // 重新初始化 WebSocket 连接
        }
      }, 5000); // 5秒后尝试重连
    },
  },
  beforeDestroy() {
    if (this.websocket) {
      this.websocket.close(); // 组件销毁时关闭 WebSocket 连接
    }
    this.stopHeartbeat(); // 停止心跳
    if (this.reconnectionTimer) {
      clearTimeout(this.reconnectionTimer); // 清除重连定时器
    }
  },
};
