import * as echarts from "echarts";
import { Message } from "element-ui";
import { pxToResponsivePx } from "@/utils/pxToResponsivePx";
export const getColorG = [
  "#06CA5D",
  "#1652FF",
  "#FF7800",
  "#009FF1",
  "#D700F1",
  "#FF3236",
  "#FFD200",
  "#7EC000",
  "#8402F4",
  "#00CDA8",
  "#BA0004",
  "#03883E",
  "#0627EC",
  "#9E00B1",
  "#AE5200",
  "#006792",
  "#B49400",
  "#476B00",
  "#5700B1",
  "#00807B",
  "#FFA3A5",
  "#78F2AE",
  "#609CFF",
  "#F181FF",
  "#FFC38E",
  "#86D9FF",
  "#FFF6CA",
  "#D1F06F",
  "#BB6BFF",
  "#ABFFF0",
  "#FF7073",
  "#3EE689",
  "#0C7BF9",
  "#EA41FF",
  "#FFA04B",
  "#44BFFF",
  "#FFEA88",
  "#A4D40D",
  "#A439FF",
  "#54FFE1",
];
// 处理x轴 or y轴 的超出隐藏方法
export function axisLabelFormatter(params, index = 4) {
  var newstr = "";
  for (var i = 0; i < params.length; i += index) {
    var tmp = params.substring(i, i + index);
    newstr += tmp + "";
  }
  if (newstr.length > index) return newstr.substring(0, index) + "...";
  else return newstr;
}
//  我尽我能力备注了  热心人你有幸修改这份代码 依稀辨认吧
export function getPieces(maxValue) {
  // console.log('输入'+maxValue)
  let pieces = [];
  let splitNumber = (maxValue + "").length + 0;
  function getText(averageStr) {
    let str = "";
    averageStr.split("").forEach((v, i) => {
      if (i + 1 == averageStr.length) return;
      str = str + "0";
    });
    return str;
  }
  function getZhen(averageStr) {
    let now = averageStr[0] + getText(averageStr);
    let yu = Math.floor(averageStr - now);
    let ban = Math.floor(now / 2);
    if (yu > ban || yu == ban) return Number(now) + Number(now);
    return now;
  }
  // 计算最大和最小
  function getSUm(num, chashu, inx = 0) {
    // 有inx 就是计算最大 反之就是最小
    return num * (chashu + inx);
  }
  let average = Math.floor(maxValue / splitNumber);
  for (let i = splitNumber; i > 0; i--) {
    let averageStr = average + "";
    let newAverage = getZhen(averageStr); // 平均数 向整数靠齐
    //  差数
    let chashu = splitNumber - i;
    let min = getSUm(newAverage, chashu); // 最小
    if (splitNumber != i) min++;
    // console.log(newAverage)
    let max = getSUm(newAverage, chashu, 1); // 最大
    // console.log(maxValue)
    // console.log(max)
    if (maxValue > max) {
      if (maxValue - max !== 1) {
        pieces.unshift({
          min: min || 0,
          max: max || 0,
        });
      }
    }
  }

  let obj = { splitNumber, pieces };
  if (pieces[0]?.max == maxValue) return obj;

  pieces = [
    {
      min: pieces[0]?.max + 1 || 0,
      max: getZhen(average + "") * (splitNumber + 1),
      //  maxValue
    },
    ...pieces,
  ];
  return { splitNumber, pieces };
}
// 单位换算成W
export function formatNumber(num, danwei = "w", inx = 2) {
  num = Number(num);
  if (num == 0 || (num > 0 && num < 1000)) {
    return num + "";
  } else if (num >= 1000 && num < 10000) {
    return (num / 10000).toFixed(2) + "w";
  } else {
    return (num / 10000).toFixed(inx) + danwei;
  }
}
// js将数字转换成万、亿、万亿
export const numberFormat = (value) => {
  var param = {};
  var k = 10000,
    sizes = ["", "万", "亿", "万亿"],
    i;
  if (value < k) {
    param.value = value;
    param.unit = "";
  } else {
    i = Math.floor(Math.log(value) / Math.log(k));
    param.value = (value / Math.pow(k, i)).toFixed(2);
    param.unit = sizes[i];
  }
  return param.value;
};
// js将数字转换成万 并且保留两位小数
export const keepTwoDecimalFull = (num) => {
  if (num > 10000) {
    let result = num / 10000;
    result = Math.floor(result * 100) / 100;
    var s_x = result.toString(); //将数字转换为字符串
    var pos_decimal = s_x.indexOf("."); //小数点的索引值
    // 当整数时，pos_decimal=-1 自动补0
    if (pos_decimal < 0) {
      pos_decimal = s_x.length;
      s_x += ".";
    }
    // 当数字的长度< 小数点索引+2时，补0
    while (s_x.length <= pos_decimal + 2) {
      s_x += "0";
    }
    s_x += "万";
  } else {
    s_x = num;
  }
  return s_x;
};
/**
 *
 * @param {*} domName
 * @returns
 */
export function getMyOldChart(domName) {
  let myChart = echarts.getInstanceByDom(document.getElementById(domName));
  if (!myChart) {
    // console.log('新的')
    myChart = echarts.init(document.getElementById(domName));
  } else {
    // console.log('有过了')
    myChart.dispose();
    myChart.clear();
    myChart = null;
    myChart = echarts.init(document.getElementById(domName));
  }
  window.addEventListener("resize", () => {
    myChart.resize();
  });
  return myChart;
}
// 获取echart实例 并判断
export function getMyChart(domName) {
  let myChart = echarts.getInstanceByDom(document.getElementById(domName));
  if (!myChart) {
    // console.log('新的')
    myChart = echarts.init(document.getElementById(domName));
  } else {
    // console.log('有过了')
    // myChart.dispose()
    // myChart.clear()
    // myChart =null
    // myChart = echarts.init(document.getElementById(domName));
  }
  window.addEventListener("resize", () => {
    myChart.resize();
  });
  return myChart;
}
// 获取echart实例 并判断 升级版
export function getMyNewChart(domName, myChart) {
  if (!myChart) {
    myChart = echarts.init(document.getElementById(domName));
    window.addEventListener("resize", () => {
      myChart.resize();
    });
  } else {
    myChart.dispose(); // 销毁
    myChart = echarts.init(document.getElementById(domName));
  }
  return myChart;
}
// 清除echarts的实列
export function clearCheat(domName) {
  let myChart = getMyChart(domName);
  myChart.dispose();
  myChart.clear();
}
// 自动轮播功能
export function handleChartLoop(option, myChart, my_fn) {
  if (!myChart) return;
  let currentIndex = -1; // 当前高亮图形在饼图数据中的下标
  let changePieInterval = setInterval(selectPie, 1000); // 设置自动切换高亮图形的定时器

  // 取消所有高亮并高亮当前图形
  function highlightPie() {
    // 遍历饼图数据，取消所有图形的高亮效果
    for (var idx in option.series[0].data) {
      myChart.dispatchAction({
        type: "downplay",
        seriesIndex: 0,
        dataIndex: idx,
      });
    }
    // 高亮当前图形
    myChart.dispatchAction({
      type: "highlight",
      seriesIndex: 0,
      dataIndex: currentIndex,
    });
    if (my_fn) my_fn({ currentIndex });
  }

  // 用户鼠标悬浮到某一图形时，停止自动切换并高亮鼠标悬浮的图形
  myChart.on("mouseover", (params) => {
    clearInterval(changePieInterval);
    currentIndex = params.dataIndex;
    highlightPie();
  });

  // 用户鼠标移出时，重新开始自动切换
  myChart.on("mouseout", (params) => {
    if (changePieInterval) {
      clearInterval(changePieInterval);
    }
    changePieInterval = setInterval(selectPie, 1000);
  });

  // 高亮效果切换到下一个图形
  function selectPie() {
    var dataLen = option.series[0].data.length;
    currentIndex = (currentIndex + 1) % dataLen;
    highlightPie();
  }
}
// tooltipBg
export const tooltipBg = {
  // backgroundColor: "rgba(0,0,0,.6)",
  //    borderColor: "rgba(147, 235, 248, 0)",
  //    background: 'linear-gradient(0deg, #00306B 0%, rgba(0,19,38,0.6) 100%)',
  // backgroundColor: "rgba(46, 111, 157, 0.26)",
  // textStyle: {
  //     color: "#FFF",
  // },
  backgroundColor: "rgba(0,0,0,.6)",
  borderColor: "rgba(147, 235, 248, 0)",
  textStyle: {
    color: "#FFF",
    fontSize: pxToResponsivePx(12),
  },
};
export function clearDom(myChart) {
  myChart.clear();
}
// 搞轮播用的
export function getSwiperItem(ResData, inx = 4) {
  let dataX = [];
  let dataArr = [];
  let dataX2 = [];
  let dataArr2 = [];
  ResData.forEach((x, i) => {
    if (i < inx) {
      dataX.push(x.name);
      dataArr.push(x.value);
    } else {
      dataX2.push(x.name);
      dataArr2.push(x.value);
    }
  });
  return { dataX, dataArr, dataX2, dataArr2 };
}

export function autoMove(
  { dataX, dataArr, dataX2, dataArr2 },
  option,
  myChart
) {
  // if(timer) clearInterval(timer)
  // timer = null
  // 自动滚动：
  // timer = setInterval(function () {
  dataX2.push(dataX.splice(0, 1)[0]);
  dataX.push(dataX2.splice(0, 1)[0]);
  dataArr2.push(dataArr.splice(0, 1)[0]);
  dataArr.push(dataArr2.splice(0, 1)[0]);
  option.xAxis.data = dataX;
  option.series[0].data = dataArr;
  myChart.setOption(option);
  //   }, 2000);
  //   return timer
}

export function kongOption() {
  let option = {
    title: {
      text: "暂无数据",
      x: "center",
      y: "center",
      textStyle: {
        fontSize: pxToResponsivePx(14),
        fontWeight: "normal",
        color: "#ffffff",
      },
    },
  };
  return option;
}
export function getTextgeshi(txt) {
  if (!txt) return 0;
  txt = txt + "";
  return txt.replace(/(?=\B(\d{3})+$)/g, ",") || 0;
}
export function mySetTimeOut(time) {
  return new Promise((res) => setTimeout(res, time));
}

export function mySetTimer(name, fn, time) {
  if (!mySetTimer[name]) {
    mySetTimer[name] = setInterval(() => {
      // 设置新的定时器
      fn(); // 执行传入的函数
    }, time);
  } else {
    myClearTimer(name);
    mySetTimer[name] = setInterval(() => {
      // 设置新的定时器
      fn(); // 执行传入的函数
    }, time);
  }
}
export function myClearTimer(name) {
  if (mySetTimer[name]) clearInterval(mySetTimer[name]);
}
// 输入框 处理返回提示
export function inputPrompt(Res, Prompt = {}) {
  if (Res.data.code == 200) {
    Message.success(Prompt?.success || "提交成功");
    return false;
  } else {
    Message.warning(Res?.data?.msg || "提交失败！");
    return false;
  }
}
// 阴影圆心图
export function shadow() {
  return {
    name: "",
    type: "pie",
    startAngle: 90,
    radius: "50%",
    animation: false,
    hoverAnimation: false,
    center: ["50%", "40%"],
    tooltip: {
      show: false,
    },
    itemStyle: {
      normal: {
        labelLine: {
          show: false,
        },
        color: new echarts.graphic.RadialGradient(0.5, 0.5, 1, [
          {
            offset: 1,
            color: "rgba(50,171,241, 1)",
          },
          {
            offset: 0,
            color: "rgba(55,70,130, 0)",
          },
        ]),
        shadowBlur: 10,
      },
    },
    data: [
      {
        value: 0,
      },
    ],
  };
}
export function getMyChartok(domName) {
  let myChart = echarts.getInstanceByDom(document.getElementById(domName));
  if (myChart == undefined) {
    myChart = echarts.init(document.getElementById(domName));
  }
  myChart.clear();
  //   myChart.showLoading({
  //     // color:'rgba(255, 255, 255, 0)'
  //     maskColor: 'rgba(255, 255, 255, 0)',
  //   })
  return myChart;
}

export default {
  getMyOldChart,
  inputPrompt,
  shadow,
  getMyChartok,
  myClearTimer,
  mySetTimer,
  mySetTimeOut,
  formatNumber,
  getColorG,
  clearDom,
  kongOption,
  getMyNewChart,
  getTextgeshi,
  numberFormat,
  keepTwoDecimalFull,
  axisLabelFormatter,
  getPieces,
  getMyChart,
  handleChartLoop,
  tooltipBg,
  clearCheat,
  getSwiperItem,
  autoMove,
};
